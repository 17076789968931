<template>
  <div class="lpDefault-layout">
    <header>
      <h1 class="lpDefault-header">
        <img
          src="/img/top/logo.svg"
          width="207"
          height="19"
          alt="WARC AGENT"
          class="lpDefault-header__img"
        />
        <ClientOnly v-if="isDisplayHeader">
          <div v-if="isPc" class="lpDefault-header__description">
            <img
              src="/img/lp/common/icon_laurel_wreath_left.svg"
              width="13"
              height="54"
              alt=""
            />
            <p class="header-description__text">
              求人数
              <br />
              2,500件超
            </p>
            <img
              src="/img/lp/common/icon_laurel_wreath_right.svg"
              width="13"
              height="54"
              alt=""
            />
          </div>
          <div v-if="isPc" class="lpDefault-header__description">
            <img
              src="/img/lp/common/icon_laurel_wreath_left.svg"
              width="13"
              height="54"
              alt=""
            />
            <p class="header-description__text">
              一番紹介したい
              <br />
              エージェント
            </p>
            <img
              src="/img/lp/common/icon_laurel_wreath_right.svg"
              width="13"
              height="54"
              alt=""
            />
          </div>
          <div v-if="isPc" class="lpDefault-header__description">
            <img
              src="/img/lp/common/icon_laurel_wreath_left.svg"
              width="13"
              height="54"
              alt=""
            />
            <p class="header-description__text">
              転職後年収
              <br />
              150万円UP
            </p>
            <img
              src="/img/lp/common/icon_laurel_wreath_right.svg"
              width="13"
              height="54"
              alt=""
            />
          </div>
        </ClientOnly>
      </h1>
    </header>
    <slot />
    <footer class="lpDefault-footer">
      <div class="lpDefault-footer__contents">
        <div class="contents-box">
          <span class="contents-box__title">運営会社</span>
          <span class="contents-box__text"
            ><a href="https://agent.warc.jp">株式会社WARC</a></span
          >
        </div>
        <div v-if="isDisplayFooter" class="contents-box">
          <span class="contents-box__title">所在地</span>
          <span class="contents-box__text">
            〒141-0021
            <br />
            東京都品川区上大崎2-25-2 新目黒東急ビル9F
          </span>
        </div>
        <div v-if="isDisplayFooter" class="contents-box">
          <span class="contents-box__title">資本金</span>
          <span class="contents-box__text">
            800,000,000円（資本準備金含む）
          </span>
        </div>
        <div class="contents-box">
          <span class="contents-box__title">代表取締役</span>
          <span class="contents-box__text">山本 彰彦</span>
        </div>
        <div v-if="isDisplayFooter" class="contents-box">
          <span class="contents-box__title">設立年月</span>
          <span class="contents-box__text">2017年5月</span>
        </div>
        <div v-if="isDisplayFooter" class="contents-box">
          <span class="contents-box__title">従業員数</span>
          <span class="contents-box__text">90人</span>
        </div>
        <div class="contents-box">
          <span class="contents-box__title">有料職業紹介許可番号</span>
          <span class="contents-box__text">13-ユ-308828</span>
        </div>
      </div>
      <span class="lpDefault-footer__copy">&copy; 2024 WARC.inc</span>
    </footer>
  </div>
</template>

<script setup lang="ts">
const route = useRoute();

const isPc = useState(() => false);
const isDisplayHeader = useState(() => true);
const isDisplayFooter = useState(() => true);

const checkWindowWidth = () => {
  isPc.value = screen.width > 769;

  // NOTE: lp005の場合だけfooterを非表示にする部分があるので
  if (route.path.includes('005')) {
    isDisplayFooter.value = screen.width > 1200;
    isDisplayHeader.value = false;
  }
};

onMounted(() => {
  checkWindowWidth();
  window.addEventListener('resize', checkWindowWidth);
});
</script>
